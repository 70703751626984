
import SnippetPy from "../components/SnippetPy";
import Unipage from "../templates/Unipage";

export default function IfStatements() {

    return (<Unipage
        content={
            <>
                <h2>Input() and If Statements</h2>

                <p><img width="600px" src="/python_imgs/input_python.png"/></p>


                <p><img width="600px" src="/python_imgs/input_if_python.png" /></p>

                <p>In the example <code>val</code> is a variable. Variables store information.</p>

                <h3>Number Grades to Letter Grades</h3>

                <p>Create a program that converts a number grade to a letter grade. You choose the letter grade cut-offs.</p>

                <p>Use this starter code.</p>

                <SnippetPy code={
`def printLetterGrade():
    grade = #use input() here
    if grade >= 95:
        print("A+")
    if grade < 95 and grade >= 92:
        print("A")
    # Add more if statements here

if __name__ == "__main__":
    printLetterGrade()
`
                } />

                <h3>Rock-Paper-Scissors</h3>
                <p>Create a program that let's two players play Rock-Paper-Scissors</p>

            </>
        }
    />);

}